<template>
  <div id="breadcrumb" :class="{ 'has-link': route }" v-on="$listeners">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",

  props: {
    label: {
      type: String,
      required: true,
    },

    route: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
#breadcrumb {
  @extend .text-xs;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--icon-color);

  &.has-link {
    cursor: pointer;
    color: var(--secondary);
  }
}
</style>
