import { render, staticRenderFns } from "./Fields.vue?vue&type=template&id=1d8c0ffa&scoped=true&"
import script from "./Fields.vue?vue&type=script&lang=js&"
export * from "./Fields.vue?vue&type=script&lang=js&"
import style0 from "./Fields.vue?vue&type=style&index=0&id=1d8c0ffa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d8c0ffa",
  null
  
)

export default component.exports