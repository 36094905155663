<template>
  <div
    v-if="visible"
    id="list-item"
    :class="{
      'is-selected': isSelected,
      'is-disabled':
        (!subList.length && label === 'Initiate Workflow') || disabled,
    }"
    :style="{ padding: isSparse ? '16px' : '8px 16px' }"
    v-on="$listeners"
  >
    <template v-if="color">
      <BaseActionButton
        v-if="icon"
        :icon="icon"
        :color="color"
        size="14px"
        class="icon"
      />
    </template>
    <template v-else>
      <BaseIcon v-if="icon" :name="icon" inherit-color class="icon"
    /></template>

    <div class="label" :style="{ color: color }">{{ label }}</div>

    <template v-if="subList">
      <q-menu
        transition-show="scale"
        transition-hide="scale"
        anchor="top end"
        self="top start"
      >
        <q-list>
          <q-item
            v-for="submenu in subList"
            :key="submenu.id"
            clickable
            @click="$emit('subListAction', submenu)"
          >
            <q-item-section side>
              <BaseIcon v-if="icon" :name="icon" inherit-color class="icon" />
            </q-item-section>
            <q-item-section>{{ submenu.name }}</q-item-section>
          </q-item>
        </q-list>
      </q-menu>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListItem",

  props: {
    icon: {
      type: String,
      default: "",
    },

    label: {
      type: [String, Number],
      required: true,
    },

    isSparse: {
      type: Boolean,
      default: false,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    color: {
      type: String,
      default: "",
    },

    subList: {
      type: Array,
      default: () => [],
    },

    visible: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#list-item {
  display: flex;
  align-items: center;
  transition: background-color 0.25s ease;

  .icon,
  .label {
    transition: all 0.25s ease;
  }

  .icon {
    color: var(--icon-color-inverted);
    margin-right: 16px;
  }

  .label {
    flex: 1;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--hover-bg-color);

    .icon,
    .label {
      color: var(--secondary);
    }
  }

  &.is-selected {
    .icon,
    .label {
      color: var(--secondary);
    }

    .label {
      font-weight: 500;
    }
  }

  &.is-disabled {
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.65;
  }

  #action-btn {
    width: 22px !important;
    height: 22px !important;
  }
}
</style>
