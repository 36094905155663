<template>
  <BaseIcon
    id="separator"
    name="eva-chevron-right"
    inherit-color
    size="18px"
    class="q-mx-xs"
    :class="color"
  />
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Separator",

  computed: {
    ...mapState(["darkTheme"]),

    color() {
      return this.darkTheme ? "text-gray-6" : "text-gray-5";
    },
  },
};
</script>

<style lang="scss" scoped></style>
