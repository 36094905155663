<template>
  <div
    id="bot-recommendations"
    v-ripple="'gray'"
    :class="{ 'is-active': isChipsVisible }"
  >
    <BaseIcon name="mdi-robot-outline" inherit-color />

    <q-menu
      auto-close
      anchor="bottom right"
      self="top right"
      :offset="[0, 8]"
      @input="handleInput"
    >
      <div class="recommendation-chips">
        <div class="title-3 q-ma-md">Bot Recommendations</div>

        <BaseScrollbar height="200px" class="q-px-md q-my-md">
          <div class="chips">
            <div
              v-for="chip in chips"
              :key="chip"
              v-tooltip.top="chip"
              class="chip"
              @click="handleClick(chip)"
            >
              {{ chip }}
            </div>
          </div>
        </BaseScrollbar>

        <div class="title-3 q-mx-md">Options</div>

        <ListItem
          :icon="
            autoFill ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
          "
          :is-selected="autoFill"
          label="Autofill the selected value for the similiar files"
          class="q-my-sm"
          @click="autoFill = !autoFill"
        />
      </div>
    </q-menu>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "BotRecommendations",

  components: { ListItem },

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      chips: [
        "2022/02/12 18:30",
        "dolor sit amet consectetur",
        "adipisicing elit",
        "2022/04/30",
        "Yes",
        "12:30 PM",
        "963.25",
        "false",
        "04/26/2022",
        "2022/02/24 11:30 PM",
        "labore cum",
        "98563",
        "eos magnam",
        "2022-04-26",
        "fugit sequi",
      ],
      autoFill: false,
      isChipsVisible: false,
    };
  },

  methods: {
    handleInput(value) {
      this.isChipsVisible = value;
    },

    handleClick(chip) {
      this.$emit("select", chip);
    },
  },
};
</script>

<style lang="scss" scoped>
#bot-recommendations {
  height: 48px;
  width: 48px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--icon-color);

  &:hover {
    cursor: pointer;
    background-color: var(--hover-bg-color);
  }

  &.is-active {
    color: white;
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
  }
}

.recommendation-chips {
  width: 400px;

  .chips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 8px;

    .chip {
      padding: 8px;
      border: 1px solid var(--divider-color);
      border-radius: 4px;
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }
}
</style>
