<template>
  <div id="breadcrumbs">
    <template v-for="(breadcrumb, index) in breadcrumbs">
      <Breadcrumb
        :key="breadcrumb.id"
        :label="breadcrumb.label"
        :route="breadcrumb.route"
        @click="goto(breadcrumb)"
      />

      <Separator v-if="index !== breadcrumbs.length - 1" :key="index" />
    </template>
  </div>
</template>

<script>
import Breadcrumb from "./components/Breadcrumb.vue";
import Separator from "./components/Separator.vue";

export default {
  name: "Breadcrumbs",

  components: { Breadcrumb, Separator },

  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    goto(breadcrumb) {
      if (this.$store.state.profileMenus.length) {
        if (breadcrumb.query) {
          let key = breadcrumb.query.split(":");
          this.$router.push({
            name: breadcrumb.route,
            query: { [key[0]]: key[1] },
          });
        } else {
          this.$router.push({ name: breadcrumb.route });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#breadcrumbs {
  display: flex;
  align-items: center;
}
</style>
