<template>
  <div id="fields-list">
    <div v-for="field in fields" :key="field.id" class="field-wrapper">
      <!-- field -->

      <div class="field">
        <!-- text -->

        <ValidationProvider
          v-if="field.dataType === 'TEXT' || field.dataType === 'BARCODE'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <TextField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- number -->

        <ValidationProvider
          v-if="field.dataType === 'NUMBER'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{
            required: field.isMandatory,
            integer: !field.options.allowDecimals,
          }"
        >
          <NumberField
            v-model="fieldsModel[field.name]"
            is-mandatory
            :label="field.name"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- date -->

        <ValidationProvider
          v-if="field.dataType === 'DATE'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <DateField
            v-model="fieldsModel[field.name]"
            is-mandatory
            :label="field.name"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- time -->

        <ValidationProvider
          v-if="field.dataType === 'TIME'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <TimeField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- date time -->

        <ValidationProvider
          v-if="field.dataType === 'DATE_TIME'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <DateTimeField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- select (EXISTING) && select (MASTER) -->

        <ValidationProvider
          v-if="
            field.dataType === 'SELECT' &&
            ['EXISTING', 'MASTER'].includes(field.options.optionsType)
          "
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <AsyncSelectField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :error="errors[0]"
            :new-option="field.options.allowToAddNewOptions"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- select (PREDEFINED) -->

        <ValidationProvider
          v-if="
            field.dataType === 'SELECT' &&
            field.options.optionsType === 'PREDEFINED'
          "
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <SelectField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :error="errors[0]"
            :new-option="field.options.allowToAddNewOptions"
            :options="parsePredefinedSelectOptions(field)"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- boolean -->

        <ValidationProvider
          v-if="field.dataType === 'BOOLEAN'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <SingleChoiceField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :options="parseBooleanOptions(field.name)"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- omr -->

        <ValidationProvider
          v-if="field.dataType === 'OMR'"
          v-slot="{ errors }"
          :name="field.name"
          :rules="{ required: field.isMandatory }"
        >
          <SingleChoiceField
            v-model="fieldsModel[field.name]"
            :is-mandatory="field.isMandatory"
            :label="field.name"
            :options="omrOptions"
            :error="errors[0]"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- table -->

      <div v-if="field.dataType === 'TABLE'">
        <TableFieldPreview
          :label="field.name"
          :columns="field.options.columns"
          :fields-model="fieldsModel[field.name]"
          @edit="showTableField"
        />

        <TableField
          v-model="isTableFieldVisible"
          :label="field.name"
          :columns="field.options.columns"
          :fields-model="fieldsModel[field.name]"
          @save="(model) => (fieldsModel[field.name] = model)"
        />
      </div>

      <!-- ... -->

      <!-- bot recommendations -->

      <BotRecommendations
        v-if="field.dataType !== 'TABLE'"
        :model-value="fieldsModel[field.name]"
        class="recommendations"
        @select="(value) => setBotRecomendation(field, value)"
      />

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import AsyncSelectField from "@/components/common/form/async-select-field/AsyncSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TableFieldPreview from "./table-field/TableFieldPreview.vue";
import TableField from "./table-field/TableField.vue";
import BotRecommendations from "./BotRecommendations.vue";

export default {
  name: "FieldsList",

  components: {
    ValidationProvider,
    TextField,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
    SelectField,
    AsyncSelectField,
    SingleChoiceField,
    TableFieldPreview,
    TableField,
    BotRecommendations,
  },

  props: {
    fieldsType: {
      type: String,
      required: true,
    },

    fields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fieldsModel: {},
      omrOptions: [
        {
          id: 1,
          label: "Yes",
          value: "true",
        },
        {
          id: 2,
          label: "No",
          value: "false",
        },
      ],
      isTableFieldVisible: false,
    };
  },

  created() {
    this.fields.forEach((field) => {
      this.$set(this.fieldsModel, field.name, "");
    });
  },

  methods: {
    setBotRecomendation(field, value) {
      const booleanOptions = [
        field.options.truthyValue,
        field.options.falsyValue,
      ];

      switch (field.dataType) {
        case "TEXT":
          this.fieldsModel[field.name] = String(value);
          break;
        case "NUMBER":
          this.fieldsModel[field.name] = Number(value) || "";
          break;
        case "DATE":
          this.fieldsModel[field.name] = this.$day.parseDate(value);
          break;
        case "TIME":
          this.fieldsModel[field.name] = this.$day.parseTime(value);
          break;
        case "DATE_TIME":
          this.fieldsModel[field.name] = this.$day.parseDateTime(value);
          break;
        case "BOOLEAN":
          this.fieldsModel[field.name] = booleanOptions.includes(value)
            ? value
            : "";
          break;
        case "OMR":
          this.fieldsModel[field.name] = ["true", "false"].includes(value)
            ? value
            : "";
          break;
        case "SELECT":
          this.fieldsModel[field.name] = field.options.allowToAddNewOptions
            ? String(value)
            : "";
          break;
      }
    },

    parseBooleanOptions(fieldName) {
      const field = this.fields.find((_field) => _field.name === fieldName);
      return [
        {
          id: this.$nano.id(),
          label: field.options.truthyValue,
          value: field.options.truthyValue,
        },
        {
          id: this.$nano.id(),
          label: field.options.falsyValue,
          value: field.options.falsyValue,
        },
      ];
    },

    parsePredefinedSelectOptions(field) {
      return field.options.predefinedOptions.map((option) => ({
        id: this.$nano.id(),
        label: option,
        value: option,
      }));
    },

    showTableField() {
      this.isTableFieldVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#fields-list {
  .field-wrapper {
    position: relative;
    margin-bottom: 24px;

    .field {
      width: 328px;
    }

    .recommendations {
      position: absolute;
      right: 0;
      top: 30px;
    }
  }
}
</style>
