<template>
  <div id="options-picker">
    <BaseScrollbar height="256px" class="q-py-sm" @scroll="handleScroll">
      <template v-if="options.length">
        <div
          v-for="option in options"
          :key="option"
          class="option"
          :class="{ 'is-selected': isSelected(option) }"
          @click="handleClick(option)"
        >
          <BaseIcon :name="icon(option)" inherit-color class="icon" />

          <div class="label">{{ option }}</div>
        </div>
      </template>

      <div v-else class="no-option">
        <div class="q-mb-sm">No option found</div>
        <div v-if="newOption">Press "Enter" to add new option</div>
      </div>
    </BaseScrollbar>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
export default {
  name: "OptionsPicker",

  props: {
    value: {
      type: [String, Number, Array],
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    isMultiple: {
      type: Boolean,
      default: false,
    },

    newOption: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isSelected(option) {
      return this.isMultiple
        ? !!this.value.find((_option) => _option === option)
        : this.value === option;
    },

    icon(option) {
      const isSelected = this.isSelected(option);

      if (this.isMultiple) {
        return isSelected
          ? "mdi-checkbox-marked"
          : "mdi-checkbox-blank-outline";
      } else {
        return isSelected ? "eva-radio-button-on" : "eva-radio-button-off";
      }
    },

    handleClick(option) {
      this.$emit("select", option);
    },

    handleScroll: debounce(function (e) {
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      const clientHeight = e.target.clientHeight;

      const hasReachedBottom = scrollHeight - scrollTop === clientHeight;

      if (hasReachedBottom) {
        this.$emit("loadMore");
      }
    }, 300),
  },
};
</script>

<style lang="scss" scoped>
#options-picker {
  .option {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    transition: background-color 0.25s ease;

    .icon,
    .label {
      transition: all 0.25s ease;
    }

    .icon {
      color: var(--icon-color-inverted);
      margin-right: 16px;
    }

    &:hover {
      cursor: pointer;
      background-color: var(--hover-bg-color);

      .icon,
      .label {
        color: var(--secondary);
      }
    }

    &.is-selected {
      .icon,
      .label {
        color: var(--secondary);
      }

      .label {
        font-weight: 500;
      }
    }
  }

  .no-option {
    margin: 16px 16px 8px;
    text-align: center;
  }
}
</style>
