<template>
  <div id="table-field">
    <FormFieldLabel :label="label" is-mandatory />

    <BaseActionButton
      v-tooltip:secondary.top="'edit table'"
      is-flat
      color="secondary"
      icon="eva-edit-outline"
      no-border
      class="edit-table"
      @click="$emit('edit')"
    />

    <BaseScrollbar>
      <table>
        <thead>
          <tr>
            <th v-for="column in columns" :key="column._id">
              {{ column.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, index) in fieldsModel" :key="index">
            <td v-for="column in columns" :key="column._id">
              {{ row[column.name] }}
            </td>
          </tr>

          <tr>
            <th v-for="column in columns" :key="column._id">
              <div v-if="column.options.showColumnSum">
                {{ getColumnSum(column.name) }}
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </BaseScrollbar>
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";

export default {
  name: "TableFieldPreview",

  components: { FormFieldLabel },

  props: {
    label: {
      type: String,
      required: true,
    },

    columns: {
      type: Array,
      required: true,
    },

    fieldsModel: {
      type: [Array, String],
      required: true,
    },
  },

  methods: {
    getColumnSum(columnName) {
      if (!this.fieldsModel.length) {
        return 0;
      }

      return this.fieldsModel.reduce((acc, cur) => acc + cur[columnName], 0);
    },
  },
};
</script>

<style lang="scss" scoped>
#table-field {
  position: relative;

  .edit-table {
    position: absolute;
    right: 0;
    top: -8px;
  }

  table {
    table-layout: fixed;
    max-width: 100%;
    min-width: calc(100% - 1px);
    border-collapse: collapse;
    white-space: nowrap;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
        font-weight: 500;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
    }
  }
}
</style>
