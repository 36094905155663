<template>
  <div id="current-path">
    <div class="row items-center">
      <BaseIcon name="eva-hard-drive" color="secondary" class="q-mr-sm" />
      <div class="label" @click="$emit('input', 'ROOT')">C:/Imaging</div>
      <Separator />

      <BaseIcon name="mdi-briefcase" color="amber" class="q-mr-sm" />
      <div class="label" @click="$emit('input', 'WORKSPACE')">Finance</div>
      <Separator />

      <BaseIcon name="eva-archive" color="amber" class="q-mr-sm" />
      <div class="label" @click="$emit('input', 'REPOSITORY')">
        Insurance Companies
      </div>
      <Separator />

      <BaseIcon name="eva-folder" color="amber" class="q-mr-sm" />
      <div class="label" @click="$emit('input', 'BATCH')">Batch #24</div>
    </div>
  </div>
</template>

<script>
import Separator from "@/components/common/breadcrumbs/components/Separator.vue";

export default {
  name: "CurrentPath",

  components: { Separator },

  props: {
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#current-path {
  display: flex;
  align-items: center;

  .label {
    @extend .text-sm;
    font-weight: 500;
    color: var(--icon-color);
    cursor: pointer;
  }
}
</style>
