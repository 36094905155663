<template>
  <Modal :value="value" width="50vw" has-footer @input="closeModal">
    <!-- title -->

    <template #title>{{ label }}</template>

    <!-- ... -->

    <!-- column details -->

    <template #default>
      <div id="table-field">
        <BaseScrollbar>
          <table>
            <thead>
              <tr>
                <th v-for="column in columns" :key="column._id">
                  {{ column.name }}
                </th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addRow"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in tableModel" :key="index">
                <td v-for="column in columns" :key="column._id">
                  <ValidationObserver ref="form">
                    <!-- text -->

                    <ValidationProvider
                      v-if="
                        column.dataType === 'TEXT' ||
                        column.dataType === 'BARCODE'
                      "
                      v-slot="{ errors }"
                      :name="column.name"
                      :rules="{ required: column.isMandatory }"
                    >
                      <TextField
                        v-model="row[column.name]"
                        :is-mandatory="column.isMandatory"
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- number -->

                    <ValidationProvider
                      v-if="column.dataType === 'NUMBER'"
                      v-slot="{ errors }"
                      :name="column.name"
                      :rules="{
                        required: column.isMandatory,
                        integer: !column.options.allowDecimals,
                      }"
                    >
                      <NumberField
                        v-model="row[column.name]"
                        is-mandatory
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- date -->

                    <ValidationProvider
                      v-if="column.dataType === 'DATE'"
                      v-slot="{ errors }"
                      :name="column.name"
                      :rules="{ required: column.isMandatory }"
                    >
                      <DateField
                        v-model="row[column.name]"
                        is-mandatory
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- time -->

                    <ValidationProvider
                      v-if="column.dataType === 'TIME'"
                      v-slot="{ errors }"
                      :name="column.name"
                      :rules="{ required: column.isMandatory }"
                    >
                      <TimeField
                        v-model="row[column.name]"
                        :is-mandatory="column.isMandatory"
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->

                    <!-- date time -->

                    <ValidationProvider
                      v-if="column.dataType === 'DATE_TIME'"
                      v-slot="{ errors }"
                      :name="column.name"
                      :rules="{ required: column.isMandatory }"
                    >
                      <DateTimeField
                        v-model="row[column.name]"
                        :is-mandatory="column.isMandatory"
                        :error="errors[0]"
                      />
                    </ValidationProvider>

                    <!-- ... -->
                  </ValidationObserver>
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeRow(index)"
                  />
                </td>
              </tr>

              <tr>
                <th v-for="column in columns" :key="column._id">
                  <div v-if="column.options.showColumnSum" class="q-ml-sm">
                    {{ getColumnSum(column.name) }}
                  </div>
                </th>
                <td style="height: 56px"></td>
              </tr>
            </tbody>
          </table>
        </BaseScrollbar>
      </div>
    </template>

    <!-- ... -->

    <!-- footer -->

    <template #footer>
      <BaseButton is-flat label="cancel" class="q-mr-sm" @click="closeModal" />

      <BaseButton label="save" @click="save" />
    </template>

    <!-- ... -->
  </Modal>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import { cloneDeep } from "lodash-es";

export default {
  name: "TableField",

  components: {
    Modal,
    ValidationObserver,
    ValidationProvider,
    TextField,
    NumberField,
    DateField,
    TimeField,
    DateTimeField,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      required: true,
    },

    columns: {
      type: Array,
      required: true,
    },

    fieldsModel: {
      type: [Array, String],
      required: true,
    },
  },

  data() {
    return {
      tableModel: [],
      omrOptions: [
        {
          id: 1,
          label: "Yes",
          value: "true",
        },
        {
          id: 2,
          label: "No",
          value: "false",
        },
      ],
    };
  },

  created() {
    if (this.fieldsModel.length) {
      this.tableModel = cloneDeep(this.fieldsModel);
      return;
    }

    this.addRow();
  },

  methods: {
    closeModal() {
      this.$emit("input", false);
    },

    addRow() {
      const model = {};

      this.columns.forEach((column) => {
        model[column.name] = "";
      });

      this.tableModel.push(model);
    },

    removeRow(rowIdx) {
      this.tableModel.splice(rowIdx, 1);
    },

    getColumnSum(columnName) {
      if (!this.tableModel.length) {
        return 0;
      }

      return this.tableModel.reduce((acc, cur) => acc + cur[columnName], 0);
    },

    async save() {
      let isValid = true;

      for (let form of this.$refs.form) {
        isValid = await form.validate();
      }

      if (!isValid) {
        return;
      }

      this.$emit("save", this.tableModel);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
#table-field {
  margin-top: 4px;

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
        font-weight: 500;
      }
    }

    th,
    td {
      padding: 8px;
      border: 1px solid var(--divider-color);
    }

    .action {
      width: 52px;
    }
  }
}
</style>
