<template>
  <div id="index-fields">
    <ValidationObserver ref="form">
      <div class="header">Folders</div>

      <!-- repository -->

      <ValidationProvider
        v-slot="{ errors }"
        name="repository"
        :rules="{ required: true }"
      >
        <SelectField
          v-model="repository"
          is-mandatory
          :options="repositories"
          :error="errors[0]"
        />
      </ValidationProvider>

      <!-- ... -->

      <!-- mandatory fields -->

      <div class="header q-mt-lg">Mandatory Fields</div>
      <FieldsList fields-type="mandatory" :fields="mandatoryFields" />

      <!-- ... -->

      <!-- optional fields -->

      <div class="header q-mt-lg">Optional Fields</div>
      <FieldsList fields-type="optional" :fields="optionalFields" />

      <!-- ... -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import FieldsList from "./components/FieldsList.vue";

export default {
  name: "Fields",

  components: {
    ValidationObserver,
    ValidationProvider,
    SelectField,
    FieldsList,
  },

  props: {
    selectedFile: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      repository: "",
      repositories: [
        {
          id: this.$nano.id(),
          label: "Economic Affairs",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Expenditure",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Investment",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Financial Services",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Revenue",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Public Enterprises",
          value: 1,
        },
      ],
      fields: [
        {
          _id: "ykle6h6GlMxf6Z9C0dXkQ",
          name: "Text",
          dataType: "TEXT",
          isMandatory: true,
          includeInFolderStructure: true,
          level: 1,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "bMAtXjc818sn8JDUSX9IY",
          name: "Number",
          dataType: "NUMBER",
          isMandatory: true,
          includeInFolderStructure: true,
          level: 2,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "MrqHnxXPdmcLmz8gL5BAL",
          name: "Date",
          dataType: "DATE",
          isMandatory: true,
          includeInFolderStructure: true,
          level: 3,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "QLCUN2SvWcDK7uKfgzBFd",
          name: "Time",
          dataType: "TIME",
          isMandatory: true,
          includeInFolderStructure: true,
          level: 4,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "skyL6QFrEdQy06I4qt9Rz",
          name: "Date Time",
          dataType: "DATE_TIME",
          isMandatory: true,
          includeInFolderStructure: false,
          level: 0,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "4sAVNdle9hsaiE6dgyodm",
          name: "Boolean",
          dataType: "BOOLEAN",
          isMandatory: true,
          includeInFolderStructure: false,
          level: 0,
          options: {
            allowDecimals: true,
            truthyValue: "Yes",
            falsyValue: "No",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "IiMvceoDM8g-5QCv3XL32",
          name: "Select",
          dataType: "SELECT",
          isMandatory: false,
          includeInFolderStructure: false,
          level: 0,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "PREDEFINED",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [
              "China",
              "India",
              "Japan",
              "Russia",
              "USA",
              "Germany",
              "France",
            ],
            allowToAddNewOptions: true,
            columns: [],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
        {
          _id: "3TswwtT4ZXP9IpcHMaNqS",
          name: "Products",
          dataType: "TABLE",
          isMandatory: false,
          includeInFolderStructure: false,
          level: 0,
          options: {
            allowDecimals: true,
            truthyValue: "true",
            falsyValue: "false",
            optionsType: "EXISTING",
            masterTable: "",
            masterTableColumn: "",
            predefinedOptions: [],
            allowToAddNewOptions: true,
            columns: [
              {
                _id: "yXCDaYHkFBRvJ18-Q7xL2",
                name: "Item",
                dataType: "TEXT",
                isMandatory: true,
                options: {
                  allowDecimals: true,
                  showColumnSum: false,
                  truthyValue: "true",
                  falsyValue: "false",
                  optionsType: "EXISTING",
                  masterTable: "",
                  masterTableColumn: "",
                  predefinedOptions: [],
                  allowToAddNewOptions: true,
                  barcodeType: "",
                  barcodePrefix: "",
                  barcodeLength: "",
                },
              },
              {
                _id: "p65Y9sLfiu-03RrlVs9hh",
                name: "Quantity",
                dataType: "NUMBER",
                isMandatory: true,
                options: {
                  allowDecimals: false,
                  showColumnSum: true,
                  truthyValue: "true",
                  falsyValue: "false",
                  optionsType: "EXISTING",
                  masterTable: "",
                  masterTableColumn: "",
                  predefinedOptions: [],
                  allowToAddNewOptions: true,
                  barcodeType: "",
                  barcodePrefix: "",
                  barcodeLength: "",
                },
              },
              {
                _id: "KD_y9XG7GCiQqGtHGkmMA",
                name: "Price",
                dataType: "NUMBER",
                isMandatory: true,
                options: {
                  allowDecimals: true,
                  showColumnSum: true,
                  truthyValue: "true",
                  falsyValue: "false",
                  optionsType: "EXISTING",
                  masterTable: "",
                  masterTableColumn: "",
                  predefinedOptions: [],
                  allowToAddNewOptions: true,
                  barcodeType: "",
                  barcodePrefix: "",
                  barcodeLength: "",
                },
              },
            ],
            barcodeType: "",
            barcodePrefix: "",
            barcodeLength: "",
          },
        },
      ],
    };
  },

  computed: {
    mandatoryFields() {
      return this.fields.filter((field) => field.isMandatory);
    },

    optionalFields() {
      return this.fields.filter((field) => !field.isMandatory);
    },
  },
};
</script>

<style lang="scss" scoped>
#index-fields {
  .header {
    @extend .text-xs;
    letter-spacing: 0.07em;
    padding: 12px 16px;
    color: var(--title-3-color);
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
}

.theme-light {
  .header {
    background-color: rgba($color: $gray-6, $alpha: 0.06);
  }
}

.theme-dark {
  .header {
    background-color: rgba($color: $gray-5, $alpha: 0.06);
  }
}
</style>
