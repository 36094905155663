<template>
  <div>
    <!-- header -->

    <div class="row items-center q-mb-lg">
      <div>
        <!-- title -->

        <div class="title-1 q-mb-xs">Scan & Import</div>

        <!-- ... -->

        <!-- breadcrumbs -->

        <Breadcrumbs :breadcrumbs="breadcrumbs" />

        <!-- ... -->
      </div>

      <q-space />

      <!-- quick help -->

      <BaseActionButton
        icon="eva-question-mark"
        color="secondary"
        @click="$emit('quickHelp')"
      />

      <!-- ... -->

      <BaseButton
        icon="eva-printer-outline"
        label="quick scan"
        class="q-ml-md"
      />
    </div>

    <!-- ... -->

    <div class="row items-center q-mb-lg">
      <CurrentPath v-model="activePath" />

      <q-space />
      <BaseActionButton is-flat icon="mdi-file-plus-outline" class="q-mr-sm" />
      <BaseActionButton is-flat icon="mdi-file-check-outline" />

      <BaseSeparator is-vertical has-inset class="q-mx-md" />

      <BaseActionButton is-flat icon="mdi-table-split-cell" class="q-mr-sm" />
      <BaseActionButton is-flat icon="mdi-table-merge-cells" />
    </div>

    <div class="row">
      <!-- files -->

      <div>
        <div v-for="file in files" :key="file" class="file-card">
          <div class="title">{{ file }}</div>
          <div class="stats">
            <div class="stat">2022-Apr-27 12:51 AM</div>
            <BaseIcon name="mdi-circle-small" inherit-color class="icon" />
            <div class="stat">3.6 MB</div>
          </div>
        </div>
      </div>

      <!-- ... -->

      <div class="viewer">
        <div v-for="n in 4" :key="n" class="">
          <div class="wrapper">
            <img src="@/assets/capture/thumbnail.png" alt="thumnail" />
          </div>
        </div>
      </div>

      <div class="fields q-pa-md">
        <Fields />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
import CurrentPath from "../../components/CurrentPath.vue";
import Fields from "./components/fields/Fields.vue";

export default {
  name: "CaptureFileView",

  components: { Breadcrumbs, CurrentPath, Fields },

  data() {
    return {
      breadcrumbs: [
        {
          id: this.$nano.id(),
          label: "home",
          route: "capture-overview",
        },
        {
          id: this.$nano.id(),
          label: "scan & import",
          route: "",
        },
      ],
      files: [
        "Vue road to enterprise.pdf",
        "Kotlin Programming.epub",
        "EZOFIS V5 Menu List.xlsx",
        "Viewer Design.pptx",
        "Balance Sheet.pdf",
        "Income Statement.xlsx",
      ],
      activePath: "ROOT",
    };
  },
};
</script>

<style lang="scss" scoped>
.file-card {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 16px;
  width: 256px;
  margin-bottom: 16px;

  .title {
    font-weight: 600;
    margin-bottom: 8px;
  }

  .icon {
    color: var(--icon-color-inverted);
  }

  .stats {
    display: flex;
    align-items: center;

    .stat {
      @extend .text-sm;
      color: var(--icon-color);
    }
  }

  &:nth-child(3) {
    border-left: 4px solid var(--secondary);
  }
}

.viewer {
  flex: 1;
  margin: 0px 16px;

  .wrapper {
    background-color: var(--component-bg-color);
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 16px;
    padding: 16px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.fields {
  width: 416px;
  background-color: var(--component-bg-color);
  border-radius: 4px;
}
</style>
